<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>AI-Driven Process Revolution</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
        <div class="itemsTwo">
          <p>Lead the future of process optimization with AI-Powered Process Cognition. Utilize AI-driven insights and simulations to refine workflows, enhance efficiency, and innovate with precision and agility.</p>
        </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/artificial-intelligence-concept.webp" alt="Software developer" width="278"
                        height="262" onerror="this.src='assets/images/artificial-intelligence-concept.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-workflow-icon.svg" alt="AI-Driven icon" width="90" height="90">
                        <p>Streamlined Workflow Configuration</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/derive-optimal-icon.svg" alt="AI-Driven icon" width="90" height="90">
                        <p>Optimal Efficiency Pathways</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/unlock-insights-icon.svg" alt="AI-Driven icon" width="90" height="90">
                        <p>Actionable Process Insights</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/customized-process-icon.svg" alt="AI-Driven icon" width="90" height="90">
                        <p>Tailored Improvement Recommendations</p>
                    </div>
                </div>
            </div>
  
        </div>   
  
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Easily configure workflows, monitor parameters, and set schedules for seamless process management.</li>
                            <li>Identify and implement optimal pathways to maximize efficiency and process performance.</li>
                            <li>Analyze visual insights into process performance, including diversions, delays, and bottlenecks.</li>
                            <li>Receive tailored process improvement recommendations based on industry benchmarks and organizational data.</li>
                            <li>Simulate process changes to test efficiency gains and observe real-time outcomes before implementation.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-four.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
       
  
  
    </div>
  </section>
  
  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>