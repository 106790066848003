<!--footer-starts-->

<footer>
  <div class="container-main scroll-elem fade-in-bottom">

    <div class="footerLeftContent">
      <div class="onlyForTab">
        <h2>Contact Us</h2>
      <div class="mb-30">
        <div class="action-wrapper">
          <a href="tel:4401268533827"><img src="assets/images/icons/call-icon.svg" alt="Call icon" width="24" height="24"><span>(+11) 01234 567 890</span></a>
          <a href="javascript:void(0)" role="button" title="Copy Phone Number" (click)="copyToClipboard('01234567890')"><img src="assets/images/icons/copy-icon.svg" alt="Copy icon" width="24" height="24"></a>
        </div>
        <div class="action-wrapper">
          <a href="mailto:info@signitiveai.com"><img src="assets/images/icons/mail-icon.svg" alt="Write to us icon" width="24" height="24"><span>info&#64;signitiveai.com</span></a>
          <a href="javascript:void(0)" role="button" title="Copy E-Mail" (click)="copyToClipboard('info@signitiveai.com')"><img src="assets/images/icons/copy-icon.svg" alt="Copy icon" width="24" height="24"></a>
        </div>
        <!-- <div *ngIf="showCopied" class="copy-message mb-20">
          Copied to clipboard!
        </div> -->   
        
        <button type="button" class="btn btn-orange" aria-label="Quick Chat" (click)="showQuickChatModal()">
          <span>Quick Chat</span>
          <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
        </button>        
      </div>

      <div class="social-wp">
        <p>Follow Us On:</p>
        <div class="social-icons">
          <a href="#" target="_blank" title="VK"><img src="assets/images/icons/vk-icon.svg" alt="vk icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Pinterest"><img src="assets/images/icons/pinterest-icon.svg" alt="Pinterest icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Instagram"><img src="assets/images/icons/instagram-icon.svg" alt="Instagram icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Twitter"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Facebook"><img src="assets/images/icons/facebook-icon.svg" alt="Facebook icon" width="24" height="24"></a>
        </div>
      </div>

      </div>
    </div>

    <div class="footerRightContent">
      <div class="row-flex">
        <div class="items-col-4">
          <h2>Main</h2>
          <ul class="mainNav">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="merfx">MerfX</a></li>
            <li><a routerLink="regzen">RegZen</a></li>
            <li><a routerLink="mortgage-solution">Mortgage Solution</a></li>
            <!-- <li><a routerLink="/">What We Do</a></li>
            <li><a routerLink="/">How Do We Do It</a></li> -->
            <!-- <li><a routerLink="/">Insight</a></li> -->
            
          </ul>
        </div>
        <!-- <div class="items-col-4">
          <h2>Media</h2>
          <ul class="mainNav">
            <li><a routerLink="/">Press &amp; Media</a></li>
            <li><a routerLink="/">Publications</a></li>
            <li><a routerLink="/">Articles</a></li>
            <li><a routerLink="/">Advertise</a></li>
          </ul>
        </div> -->
        <div class="items-col-4">
          <h2>About</h2>
          <ul class="mainNav">
            <li><a routerLink="who-we-are">Who we are</a></li>
            <li><a routerLink="our-values">Our Values</a></li>
            <li><a routerLink="esg">ESG</a></li>
            <li><a routerLink="blogs">Blogs</a></li>
          </ul>
        </div>
        <div class="items-col-4">
          <div class="">
            <h2>Work With Us</h2>
            <ul class="mainNav">
              <li><a routerLink="career">Career</a></li>
              <li><a routerLink="contact-us">Contact Us</a></li>
            </ul>
          </div>   
        </div>
      </div>
    </div>

  </div>
  <div class="footerBg"></div>
</footer>

<section class="footerBottomSection">
<div class="container-main">
  <div class="row-flex">
    <div class="items-col-2 copyRightText">
      <p>&copy; 2024 All Right Reserved.</p>
    </div>
    <div class="items-col-2 bottomNav">
      <ul>
        <!-- <li><a href="#">Sitemap</a></li> -->
        <li><a routerLink="privacy-policy">Privacy Policy</a></li>
        <li><a routerLink="terms-of-use">Terms of Use</a></li>
        <li><a routerLink="cookies-policy">Cookies Policy</a></li>
      </ul>
    </div>
  </div>
</div>
</section>

  <button (click)="gotoTopFunction()" class="animate" id="gototop-btn">
    <img src="assets/images/icons/arrow-up.svg" alt="Scroll to Top" width="40" height="40">
    <span>Scroll to Top</span>
  </button>


  <app-cookies-consent></app-cookies-consent>
  <app-quick-chat></app-quick-chat>

  <app-popup-modal></app-popup-modal>