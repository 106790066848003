<!-- main container beginning here -->
<section class="section-main topPadding">
  <div class="video-wp">
    <video [muted]="'muted'" autoplay="autoplay" loop="loop" playsinline id="video-start">
      <source src="assets/images/banner/home-page-banner.mp4" type="video/mp4">
    </video>
  </div>
  <div class="inner-wrapper">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-lg-col-8">
          <div class="">
            <h1>Empower your business with data-driven insights.</h1>
            <p>Elevate your organization with advanced intelligent solutions for data management, discovery, and visualization</p>
            <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="sectionContent">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-lg-col-5 orderOne itemVCenter">
        <h2>Trust in your data's integrity with our AI solutions.</h2>
        <p>Empower your decision-making with confidence through our AI solutions. We deliver rapid and dynamic data
          discovery,
          advanced visualization, and sustainable data governance to navigate your intricate data landscape.</p>
        <p>Harness our solution, guided by a data blueprint to unveil concealed opportunities, discern threats, and
          evaluate
          the impact of change, providing a nuanced understanding of your infrastructure. Armed with this knowledge,
          optimize
          operations, drive efficiency, and mitigate risks for your organization.</p>
      </div>
      <div class="items-lg-col-7 orderTwo">
        <div class="itemsImg">
          <img loading="lazy" src="assets/images/slider-img-1.webp" alt="slider one" width="918" height="583"
            onerror="this.src='assets/images/slider-img-1.png'">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sectionOurProducts">
  <div class="container-main">
    <h2 class="mb-30">Our Products</h2>
    <div class="row-flex">
      <div class="items-col-3">
        <div class="products-card">
          <div class="img-wp">
            <img loading="lazy" src="assets/images/merfx-img.webp" alt="MerfX img" width="512" height="192"
              onerror="this.src='assets/images/merfx-img.png'">
          </div>
          <div class="content-wp">
            <img src="assets/images/icons/merfx.svg" alt="MerfX" width="124" height="36">
            <h3>MerfX</h3>
            <p>Merf is a comprehensive solution for car sales and finance businesses, automating tasks, improving....</p>
            <button type="button" class="btn btn-yellow" aria-label="Learn More" routerLink="/merfx">Learn More</button>
          </div>
        </div>
      </div>

      <div class="items-col-3">
        <div class="products-card">
          <div class="img-wp">
            <img loading="lazy" src="assets/images/customer-handling-img.webp" alt="Customer Handling img" width="512"
              height="192" onerror="this.src='assets/images/customer-handling-img.png'">
          </div>
          <div class="content-wp">
            <img src="assets/images/icons/customer-handling.svg" alt="Customer Handling" width="35" height="36">
            <h3>RegZen</h3>
            <p>Harness the power of AI to optimize processes and drive success...</p>
            <button type="button" class="btn btn-yellow" aria-label="Learn More"  routerLink="/regzen">Learn
              More</button>
          </div>
        </div>
      </div>

      <div class="items-col-3">
        <div class="products-card">
          <div class="img-wp">
            <img loading="lazy" src="assets/images/data-synergy-img.webp" alt="Data Synergy img" width="512"
              height="192" onerror="this.src='assets/images/data-synergy-img.png'">
          </div>
          <div class="content-wp">
            <img src="assets/images/icons/data-synergy.svg" alt="Data Synergy" width="36" height="36">
            <h3>Mortgage Solution </h3>
            <p>Optimize your lending process with our comprehensive suite of solutions...</p>
            <button type="button" class="btn btn-yellow" aria-label="Learn More" routerLink="/mortgage-solution">Learn More</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>



<!-- <section class="sectionPrimaryCard">
  <div class="card-inner">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-3 flexVcenter">
          <div class="titleFixed">
            <h2>Product X Features</h2>
          </div>
          <div class="card-primary cardBlue card-next-level--">
            <h3>Use data to get 360-degree view of your business</h3>
            <p>A simple solution where companies dealing with lengthy processes and documents can leverage digital
              self service, ease of navigation through process workflows, experience granular & aggregate Lineage and
              receive process improvement recommendations using artificial intelligence techniques in an
              integration-free environment.</p>
          </div>
        </div>
        <div class="items-col-8">
          <div class="leftSpace">
            <div class="row-flex">
              <div class="items-col-2">
                <div class="card-primary blueLight mb-20 cardMarginTop">
                  <h3>Global Document Ontology</h3>
                  <p>Pre-defined set of document formats and meta-data per business process, ability to capture new
                    document types and available to be used by workflow and Lineage tools</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_GLOBAL_DOCUMENT_ONTOLOGY)">Read More</a>
                  </div>
                </div>
                <div class="card-primary">
                  <h3>Cross-App Lineage</h3>
                  <p>Capture and report granular and aggregated data lineage across applications</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button"  (click)="onReadMoreClick(paths.PATH_CROSS_APP_LINEAGE)">Read More</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2 cardFlex">
                <div class="card-primary orderOne mb-20">
                  <h3>Intelligent Process Automation</h3>
                  <p>Pre-defined workflow processes with ability to adapt and add new workflows.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_INTELLIGENT_PROCESS_AUTOMATION)">Read More</a> 
                  </div>
                </div>
                <div class="card-primary blueLight orderTwo cardMarginBtm">
                  <h3>Cognitive Process Mining</h3>
                  <p>Provide insights for process delays and recommendations for efficiency improvement.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_COGNITIVE_MINING)">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->



<!-- <section class="sectionWork">
  <div class="container-main">
    <h2 class="mb-30">How does it work?</h2>
    <div class="row-flex">
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/cross-lg-icon.svg" alt="Cross icon" width="60" height="60">
          <h3>ROI</h3>
          <p>With Product X, you can accelerate your data projects and achieve faster results. Its powerful features,
            such as rapid data discovery, mapping, augmentation, and model validation, enable users to share
            interactive, real-time data views across their organization with ease</p>
        </div>
      </div>
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/round-lg-icon.svg" alt="Round icon" width="60" height="60">
          <h3>Implementation</h3>
          <p>Product X offers an intuitive interface that ensures a short learning curve for users at all levels of
            your organization. You can quickly load metadata from any source using the platform's out-of-the-box
            connectors and Software Development Kit (SDK).</p>
        </div>
      </div>
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/plus-lg-icon.svg" alt="Plus icon" width="60" height="60">
          <h3>Minimise Risk</h3>
          <p>By providing you with a comprehensive understanding of your data ecosystem, Product X enables you to
            optimize processes, mitigate risks, and identify inefficiencies and gaps that can lead to project
            failures. Its organization-wide view provides you with the insights you need to make informed decisions
            and drive business success</p>
        </div>
      </div>
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/cross-lg-icon.svg" alt="Cross icon" width="60" height="60">
          <h3>Streamlined Workflow</h3>
          <p>With Product X, you can enjoy a simple workflow tool that allows you to define business processes, steps,
            dependencies, and communicate with downstream applications. Our Workflow Vault also offers pre-defined
            workflow processes with the ability to adapt and add new workflows, making it easy for you to streamline
            your workflow</p>
        </div>
      </div>
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/round-lg-icon.svg" alt="Round icon" width="60" height="60">
          <h3>Data Management</h3>
          <p>Our comprehensive document management system simplifies your workflow with a pre-defined set of document
            formats and the ability to capture new types. Our Extensible Logical Data Model enables cross-functional
            data management, and our robust Lineage Hub tracks granular and aggregated data lineage. With our Global
            Document Ontology, we offer a comprehensive solution to document management</p>
        </div>
      </div>
      <div class="items-col-3">
        <div class="our-story-card">
          <img src="assets/images/icons/plus-lg-icon.svg" alt="Plus icon" width="60" height="60">
          <h3>High Level Security</h3>
          <p>At Product X, we prioritize security compliance with industry best practices so that customers are never
            exposed to malicious code. We ensure no direct connection between Product X and user data source, highest
            level vault encryption, and option for Azure or AWS worldwide customer dedicated Product X VM. With
            Product X, you can rest assured that your data is always secure with us</p>
        </div>
      </div>

    </div>
  </div>
</section> -->



<!-- <section class="sectionBlueBg">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-8 textWhite">
        <h2>Are you ready to Accelerate Your Business?</h2>
      </div>
      <div class="items-col-3">
        <div class="card-primary blueLight">
          <p class="mb-50">Introducing Product X, the all-in-one data management solution designed to streamline your
            business processes and increase efficiency</p>
          <button type="button" class="btn btn-primary btn-primary" aria-label="Request Demo">Request
            Demo</button>
        </div>
      </div>
    </div>
  </div>
</section> -->



<!--work with starts-->
<app-trusted-by></app-trusted-by>


<!--Contact form here-->
<section class="sectionContactForm">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-2 contactText">
        <div class="">
          <h2><span>Contact</span> Signitive AI</h2>
          <p>We understand that every business is unique, and our AI products can be customized to suit your specific
            requirements. If you are interested in exploring how our solutions can drive success for your business,
            please feel
            free to reach out. We would be delighted to discuss your goals and tailor a solution that aligns perfectly
            with your
            needs.</p>
        </div>
      </div>
      <div class="items-col-2">

        <form [formGroup]="contactUsFormGroup">
          <div class="contact-form">
            <p class="large">Please fill you details below to get in touch with one of our consultants:</p>
            <div class="form-group">
              <label for="firstNameId" class="form-label"><span>*</span> First Name</label>
              <input type="text" class="form-control" id="firstNameId" name="" placeholder="Type here..."
                formControlName="firstName">
              <span class="error-msg" *ngIf="contactUsFormGroup.get('firstName').invalid && submitted">
                <span *ngIf="contactUsFormGroup.get('firstName').errors['required']">{{constants.firstNameReq}}</span>
                <span
                  *ngIf="contactUsFormGroup.get('firstName').errors['pattern']">{{constants.firstNamePattern}}</span>
              </span>
            </div>

            <div class="form-group">
              <label for="lastNameId" class="form-label">Last Name</label>
              <input type="text" class="form-control" id="lastNameId" name="lastName" formControlName="lastName"
                placeholder="Type here...">
              <!-- <span class="error-msg">Error message...</span> -->
            </div>

            <div class="form-group">
              <label for="emailId2" class="form-label"><span>*</span> E-mail</label>
              <input type="email" class="form-control" id="emailId2" name="email" formControlName="email"
                placeholder="Type your email address here...">
              <span class="error-msg" *ngIf="contactUsFormGroup.get('email').invalid && submitted">
                <span *ngIf="contactUsFormGroup.get('email').errors['required']">{{constants.emailReq}}</span>
                <span *ngIf="contactUsFormGroup.get('email').errors['email']">{{constants.emailInvalid}}</span>
              </span>
              <!-- <span class="error-msg">Error message...</span> -->
            </div>

            <div class="form-group mb-30">
              <label for="messageId2"><span>*</span> Message</label>
              <textarea class="form-control" id="messageId2" name="message" aria-label="" formControlName="message"
                rows="3" placeholder="Type your message here..."></textarea>
              <span class="error-msg" *ngIf="contactUsFormGroup.get('message').invalid  && submitted">
                <span *ngIf="contactUsFormGroup.get('message').errors['required']">{{constants.messageReq}}</span>
              </span>
              <!-- <span class="error-msg">Error message...</span> -->
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-orange" name="submit" aria-label="Submit" (click)="onSubmit($event)" style="width: 100%;">
                <span>Submit</span>
                <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
              </button>
              <!-- <button type="submit" class="btn btn-blue" name="submit" aria-label="Submit"
                (click)="onSubmit()">Submit</button> -->
            </div>
            <p class="mb-0">*by clicking on "submit" button you agree to that we mau contact you by phone, email & text
              message.
            </p>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>










<!--Contact form here-->
<!-- <app-contact-form></app-contact-form> -->