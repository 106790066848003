import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-eight',
  templateUrl: './merfx-sub-page-eight.component.html',
  styleUrls: ['./merfx-sub-page-eight.component.css']
})
export class MerfxSubPageEightComponent extends BaseClassUi implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageEightComponent) {
  context.title = 'IT Service Management | Reliable IT Operations with Merf | signitiveAi';
  context.description = 'Deliver secure and reliable IT services with Merf’s IT Service Management. From incident handling to performance monitoring, ensure operational excellence and continuous improvement.';
  context.keywords = 'ITSM, Incident Management, Data Security, Continuous Improvement, IT service management, data security, incident response, performance optimization, business continuity';
  context.seoTags();
}
