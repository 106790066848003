import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-nine',
  templateUrl: './merfx-sub-page-nine.component.html',
  styleUrls: ['./merfx-sub-page-nine.component.css']
})
export class MerfxSubPageNineComponent extends BaseClassUi implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageNineComponent) {
  context.title = 'Incident Management | Resolve Issues & Enhance Trust | signitiveAi';
  context.description = 'Resolve aftersales issues with Merf’s Incident Management System. Empower customers with a self-service portal, streamline issue handling, and ensure quick resolutions to build trust.';
  context.keywords = 'Aftersales Support, Issue Resolution, Customer Satisfaction, Incident Handling incident management, aftersales issue resolution, self-service portal, downtime reduction, customer satisfaction';
  context.seoTags();
}
