import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-four',
  templateUrl: './merfx-sub-page-four.component.html',
  styleUrls: ['./merfx-sub-page-four.component.css']
})
export class MerfxSubPageFourComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageFourComponent) {
  context.title = 'Sales Module | Accelerate Leads & Close Deals Efficiently | signitiveAi';
  context.description = 'Accelerate your sales process with Merf’s Sales Module. Manage leads, assess affordability, and streamline customer interactions for seamless vehicle sales and higher customer satisfaction.';
  context.keywords = 'Sales Automation, Lead Management, Affordability Assessment, Vehicle Sales, Sales module, lead management, affordability assessment, customer interaction, dynamic payment calculator, vehicle selection';
  context.seoTags();
}