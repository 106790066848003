import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-four',
  templateUrl: './productx-sub-page-four.component.html',
  styleUrls: ['./productx-sub-page-four.component.css']
})
export class ProductxSubPageFourComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ProductxSubPageFourComponent) {
  context.title = 'RegZen | AI-Driven Process Revolution | Optimize Workflows with AI | SignitiveAi';
  context.description = 'Redefine your workflows with AI-Driven Process Revolution. Harness AI-powered insights and simulations to streamline workflow configuration, optimize efficiency, and drive actionable improvements. Achieve unparalleled precision and agility with tailored recommendations and real-time process simulations.';
  context.keywords = 'AI-driven process optimization, workflow automation, process cognition, AI-powered insights, workflow configuration, tailored recommendations, process simulation, efficiency pathways';
  context.seoTags();
  }
