import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-three',
  templateUrl: './merfx-sub-page-three.component.html',
  styleUrls: ['./merfx-sub-page-three.component.css']
})
export class MerfxSubPageThreeComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}


function init(context: MerfxSubPageThreeComponent) {
  context.title = 'Underwriting & Automation | Faster Loan Approvals with AI | signitiveAi';
  context.description = 'Simplify the underwriting process with Merf’s automated tools. Ensure faster loan approvals, accurate credit assessments, and compliance with AI-powered decision-making and automated document verification.';
  context.keywords = 'Underwriting Automation, Loan Approval, AI Underwriting, Credit Assessment underwriting automation, AI-driven decisions, faster approvals, credit assessment, rule engine, document verification';
  context.seoTags();
}
