import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-two',
  templateUrl: './productx-sub-page-two.component.html',
  styleUrls: ['./productx-sub-page-two.component.css']
})
export class ProductxSubPageTwoComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}

function init(context: ProductxSubPageTwoComponent) {
  context.title = 'RegZen | Workflow Optimization | Streamline Business Processes | SignitiveAi';
  context.description = 'Optimize your business processes with Workflow Vault. Effortlessly define, adapt, and test workflows in real-time. Eliminate manual errors, enhance efficiency, and integrate seamlessly with downstream systems. Access updated process vaults and shared workflows for smarter operations.';
  context.keywords = 'workflow optimization, workflow vault, efficient workflow management, business process automation, workflow configuration, process customization, real-time workflow testing, process vault access';
  context.seoTags();
  }

