<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Reporting & Analytics</h1>                    
            </div>
            <div class="">                    
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button> 
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Our reporting and dashboard module helps you make informed decisions, optimize operations, and drive business growth by providing real-time insights and data-driven analysis.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/hands-of-young-businessman.webp" alt="Business Digital Transformation" width="278"
                        height="262" onerror="this.src='assets/images/hands-of-young-businessman.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/reporting-analytics-icon-1.svg" alt="Customizable Reporting System" width="90" height="90">
                        <p>Customizable Reporting System</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/reporting-analytics-icon-2.svg" alt="Multi-Dimensional Data Analysis" width="90" height="90">
                        <p>Multi-Dimensional Data Analysis</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/reporting-analytics-icon-3.svg" alt="Comprehensive Monitoring" width="90" height="90">
                        <p>Comprehensive Monitoring</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/reporting-analytics-icon-4.svg" alt="Growth and Improvement Opportunities" width="90" height="90">
                        <p>Growth and Improvement Opportunities</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>    
                    <!-- <h3 class="mb-10">Know about a few of the features</h3>                -->

                    <div class="mb-20">
                        <h3 class="mb-10">Customizable Reporting System</h3>
                        <ul class="regular">
                            <li>Gain actionable insights through real-time data analysis and personalized reporting capabilities.</li>
                        </ul>
                    </div> 
                    <div class="mb-20">
                        <h3 class="mb-10">Multi-Dimensional Data Analysis</h3>
                        <ul class="regular">
                            <li>Uncover patterns and correlations to make more informed decisions.</li>
                            <li>Monitor key performance metrics to drive continuous improvement.</li>
                        </ul>
                    </div>
                    <div class="mb-20">
                        <h3 class="mb-10">Comprehensive Monitoring</h3>
                        <ul class="regular">
                            <li> Track sales performance, customer satisfaction, and operational efficiency to ensure everything runs smoothly.</li>
                        </ul>
                    </div>
                    <div class="mb-20">
                        <h3 class="mb-10">Growth and Improvement Opportunities</h3>
                        <ul class="regular">
                            <li>Make data-driven decisions to identify areas for growth.</li>
                            <li>Leverage customizable dashboards to monitor key metrics and trends for ongoing optimization.</li>
                        </ul>
                    </div>
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-two.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>