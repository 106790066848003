import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-one',
  templateUrl: './merfx-sub-page-one.component.html',
  styleUrls: ['./merfx-sub-page-one.component.css']
})
export class MerfxSubPageOneComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageOneComponent) {
  context.title = 'Collection Module | Streamline Loan Recovery & Minimize Risk | signitiveAi';
  context.description = 'Streamline collection processes with Merf’s Collections Module. Enhance operational efficiency, reduce default rates, retain customers, and mitigate risks to improve overall profitability for financial institutions.';
  context.keywords = 'Collections Module, Loan Recovery, Risk Management, Customer Retention Collections module, operational efficiency, customer retention, reduce risk, loan recovery, default rates, financial institutions';
  context.seoTags();
}
