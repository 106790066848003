<!--Header html beginning here-->
<header [ngClass]="{'transparentHeader': isHome}">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1">
        <div class="header-flex">
          <div class="items-logo">
            <span class="onlyForWeb">
              <a routerLink="/">
                <img src="assets/images/signitiveAi-logo.svg" class="currentImg" alt="signitiveAi logo" width="190" height="35">
                <img src="assets/images/signitiveAi-white-logo.svg" class="hoverImg" alt="signitiveAi white logo" width="190" height="35">
              </a>
            </span>
            <span  class="onlyForMobile">
              <a routerLink="/">
                <img src="assets/images/signitiveAi-thumb-logo.svg" class="currentImg" alt="signitiveAi logo" width="48" height="36">
                <img src="assets/images/signitiveAi-thumb-white-logo.svg" class="hoverImg" alt="signitiveAi white logo" width="48" height="36">
              </a>
            </span>            
          </div>
          <div class="navigation">
            <ul>
              <!-- <li>
                <a name="nameId" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                  (click)="onSubMenuClick(0)" [ngClass]="{active: selectedMenuIndex==0}">Home</a>
              </li> -->

              <li class="dropDown" (mouseenter)="openSubMenu($event)" (mouseleave)="closeSubMenu($event)">
                <a href="javascript:void(0)" role="button" [ngClass]="{active: selectedMenuIndex==1}">Solutions</a>
                <!-- ---large mene beginning here--- -->
                <ul>
                  <li class="circleBg">
                    <div class="menu-lg-container">
                      <div class="row-flex" (click)="onSubMenuClick(1)">
                        <div class="items-col-3 menuLink">
                          <a routerLink="merfx">
                            <div class="nav-card servMenuBg2">
                              <div class="">
                                <h2>MerfX</h2>
                                <p>Empower Your Car Finance Operations with Comprehensive Data Management...</p>
                              </div>
                            </div>
                          </a>
                        </div>              
                        
                        <div class="items-col-3 menuLink">
                          <a routerLink="regzen">
                            <div class="nav-card servMenuBg1">
                              <div class="">
                                <h2>RegZen</h2>
                                <p>Harness the power of AI to optimize processes and drive success...</p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div class="items-col-3 menuLink">              
                          <a routerLink="mortgage-solution">
                            <div class="nav-card servMenuBg4">
                              <div class="">
                                <h2>Mortgage Solution</h2>
                                <p>Optimize your lending process with our comprehensive suite of solutions...</p>
                              </div>
                            </div>
                          </a>
                        </div>

                      </div>
                    </div>
                    <!-- <span class="backdrop"></span> -->
                  </li>
                </ul>
                <!-- ---large mene end here--- -->
              </li>  
              
              
              <li class="dropDown" (mouseenter)="openSubMenu($event)" (mouseleave)="closeSubMenu($event)">
                <a href="javascript:void(0)" role="button" [ngClass]="{active: selectedMenuIndex==2}">About</a>
                <!-- ---large mene beginning here--- -->
                <ul>
                  <li class="circleBg">
                    <div class="menu-lg-container">
                      <div class="row-flex menuLink mb-25" (click)="onSubMenuClick(2)">
                        <div class="items-col-3">
                          <a routerLink="who-we-are">
                            <div class="nav-card aboutMenuBg1">
                              <div class="">
                                <h2>Who We Are</h2>
                                <p>We specialize in Organizational Development, with a rich pedigree in Information
                                  Technology. Our Goal is to help our customers embrace the change with least disruption
                                  and achieve exponential growth...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="items-col-3">
                          <a routerLink="our-values">
                            <div class="nav-card aboutMenuBg2">
                              <div class="">
                                <h2>Our Values</h2>
                                <p>We believe that a vibrant company culture, which supports and nurtures our employees,
                                  is key to our success...</p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div class="items-col-3">
                          <a routerLink="esg">
                            <div class="nav-card aboutMenuBg3">
                              <div class="">
                                <h2>ESG</h2>
                                <p>We are dedicated to creating value for all stakeholders while making a positive
                                  impact on the world...</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- <div class="items-col-3">
                          <a routerLink="career">
                            <div class="nav-card aboutMenuBg4">
                              <div class="">
                                <h2>Career</h2>
                                <p>As a consulting firm, we strongly believe in importance of developing individuals,
                                  both our staffs and customers...</p>
                              </div>
                            </div>
                          </a>
                        </div> -->
                        <!-- <div class="items-col-3">
                          <a routerLink="blogs">
                            <div class="nav-card aboutMenuBg5">
                              <div class="">
                                <h2>Blogs</h2>
                                <p>Exploring the Cutting-Edge Innovations in Software Technology...</p>
                              </div>
                            </div>
                          </a>
                        </div> -->
                        <!-- <div class="items-col-1">
                          <p>New <a href="javascript:void(0)" routerLink="about-us" role="button" style="color: #007bff;">About us</a> page</p>
                        </div> -->
                      </div>
                    </div>
                    <!-- <span class="backdrop"></span> -->
                  </li>

                </ul>
                <!-- ---large mene end here--- -->
              </li>
            
              <!-- <li>
                <a name="nameId" routerLink="blogs" routerLinkActive="active" (click)="onSubMenuClick(4)"
                  [ngClass]="{active: selectedMenuIndex==3}">Blogs</a>
              </li>             -->
              <!-- <li>
                <a name="nameId" routerLink="career" routerLinkActive="active" (click)="onSubMenuClick(4)"
                  [ngClass]="{active: selectedMenuIndex==4}">Careers</a>
              </li> -->
              <li>
                <a name="nameId" routerLink="contact-us" routerLinkActive="active" (click)="onSubMenuClick(3)"
                  [ngClass]="{active: selectedMenuIndex==3}">Contact</a>
              </li>              
            </ul>            
          </div>
          <div class="itemsBtn">
            <button type="button" class="btn btnOrange" aria-label="Get In Touch">
              <span class="webIcon">Get In Touch</span>
              <img src="assets/images/icons/arrowRight-white-icon.svg" class="webIcon" alt="Arrow Icon" width="24"
                height="18">
              <img src="assets/images/icons/meeting-icon.svg" class="mobileIcon" alt="Meeting Icon" width="42"
                height="42">
            </button>
            <div class="menuBtnIcon">
              <button type="button" aria-label="Mobile menu icon" (click)="showMenu()">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.148" height="28" viewBox="0 0 31.148 28">
                  <path id="Union_15" data-name="Union 15"
                    d="M16.648,28V25h14.5v3ZM0,16V13H31.148v3ZM16.648,3V0h14.5V3Z" fill="#0f1b21" />
                </svg>
                <span>MENU</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</header>
<!--Header html end here-->


<!-- Navigation for mobile beginning here -->
<div class="mobileMenuList">
  <div class="menu-header">
    <a href="javascript:void(0)" role="button" routerLink="/" (click)="closeMenu()">
      <img src="assets/images/signitiveAi-thumb-logo.svg" alt="SignitiveAi logo" width="48" height="36">
    </a>
    <button type="button" aria-label="Close" (click)="closeMenu()" title="Close">
      <img src="assets/images/icons/cross-icon.svg" alt="Close icon" width="24" height="24">
    </button>
  </div>

  <div class="menu-list">
    <ul (click)="showDropdownMenu($event)">
      <!-- <li><a routerLink="/" (click)="closeAll()">Home</a></li> -->
      <li class="subNav"><a href="javascript:void(0)" role="button">Solutions</a>
        <ul>
          <li><a routerLink="merfx" (click)="closeMenu()">MerfX</a></li>
          <li><a routerLink="regzen" (click)="closeMenu()">RegZen</a></li>
          <li><a routerLink="mortgage-solution" (click)="closeMenu()">Mortgage Solution</a></li>
        </ul>
      </li>
      <li class="subNav"><a href="javascript:void(0)" role="button">About</a>
        <ul>
          <li><a routerLink="who-we-are" (click)="closeMenu()">Who We Are</a></li>
          <li><a routerLink="our-values" (click)="closeMenu()">Our Values</a></li>
          <li><a routerLink="esg" (click)="closeMenu()">ESG</a></li>
        </ul>
      </li>
      <li><a routerLink="blogs"  (click)="closeMenu()">Blogs</a></li>
      <li><a routerLink="career" (click)="closeMenu()">Careers</a></li>
      <li><a routerLink="contact-us" (click)="closeMenu()">Contact</a></li>
    </ul>
  </div>

  <div class="items-footer">
    <div class="btnWrapper">
      <button type="button" class="btn btn-orange" aria-label="Get In Touch">
        <span>Get In Touch</span>
        <img src="assets/images/icons/arrowRight-white-icon.svg" alt="Menu Icon" width="24" height="18">
      </button>
    </div>
    <!-- <h3>Follow Us On</h3> -->

    <div class="itemCenter">
      <div class="social-wp">
        <p class="text-center">Follow Us On:</p>
        <div class="social-icons">
          <a href="#" target="_blank" title="VK"><img src="assets/images/icons/vk-icon.svg" alt="vk icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Pinterest"><img src="assets/images/icons/pinterest-icon.svg" alt="Pinterest icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Instagram"><img src="assets/images/icons/instagram-icon.svg" alt="Instagram icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Twitter"><img src="assets/images/icons/twitter-icon.svg" alt="Twitter icon" width="24" height="24"></a>
          <a href="#" target="_blank" title="Facebook"><img src="assets/images/icons/facebook-icon.svg" alt="Facebook icon" width="24" height="24"></a>
        </div>
      </div>
    </div>

    <div class="mobileFooterMenu">
      <p>&copy; 2023 All Right Reserved. <span>|</span> </p>
      <div class="itemList">
        <a routerLink="privacy-policy">Privacy Policy</a> |
        <!-- <a routerLink="terms-of-use">Terms of Use</a> |  -->
        <a routerLink="cookies-policy">Cookies Policy</a>
      </div>
    </div>

  </div>
</div>
<!-- Navigation for mobile end here -->
<div class="siteBackdrop" (click)="closeAll()"></div>