<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Simplify Your Document Workflow</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
          <div class="itemsTwo">
              <p>Transform document management with our advanced Document Catalogue. Simplify workflows by updating and validating documents effortlessly while ensuring accuracy and compliance every step of the way.</p>
          </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/businesswoman-browsing.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/businesswoman-browsing.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/effortless-icon.svg" alt="Simplify Your Document Workflow icon" width="90" height="90">
                        <p>Efficient Data Capture</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-interpretation-icon.svg" alt="Simplify Your Document Workflow icon" width="90" height="90">
                        <p>Seamless Validation & Interpretation</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/efficient-data-icon.svg" alt="Simplify Your Document Workflow icon" width="90" height="90">
                        <p>Intelligent Data Enrichment</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/simplified-validation-icon.svg" alt="Simplify Your Document Workflow icon" width="90" height="90">
                        <p>Simplified Submission Tools</p>
                    </div>
                </div>
            </div>
  
        </div>     
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Capture and extract data seamlessly from diverse document types and formats.</li>
                            <li>Validate documents with live extraction and real-time rule-based validation capabilities.</li>
                            <li>Assign tasks, track progress, and prioritize jobs for streamlined efficiency.</li>
                            <li>Enrich and allocate data with metadata-driven warehouses and business-specific rule definitions.</li>
                            <li>Use tools for automated document validation and submission, ensuring accuracy with real-time adjustments.</li>
                            <li>Update organizational document vocabulary with access to global ontologies and extensive metadata repositories.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-one.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
      
  
    </div>
  </section>
  
  
  
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>