<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Transparent Data Evolution</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
  </section>
  
  
  <section class="commonSection">
    <div class="container-main">
  
        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
        <div class="itemsTwo">
          <p>Experience the evolution of data management with our transparent Lineage Hub. Track your data's journey from origin to transformation, empowering compliance, decision-making, and strategic foresight with unmatched transparency.</p>
        </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/blue-futuristic-stream-data.webp" alt="Software developer" width="278"
                        height="262" onerror="this.src='assets/images/blue-futuristic-stream-data.png'">
                </div>
            </div>
        </div>
  
  
        <div class="section-icons">
  
            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/intuitive-navigation-icon.svg" alt="Transparent Data Evolution icon" width="90" height="90">
                        <p>Intuitive Data Traceability</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/effortless-data-icon.svg" alt="Transparent Data Evolution icon" width="90" height="90">
                        <p>Automated Data Discovery</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/granular-data-lineage-icon.svg" alt="Transparent Data Evolution icon" width="90" height="90">
                        <p>Granular Lineage Tracking</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/seamless-exploration-icon.svg" alt="Transparent Data Evolution icon" width="90" height="90">
                        <p>Seamless Data Auditing</p>
                    </div>
                </div>
            </div>
  
        </div>   
  
        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
  
                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Track data's origin and journey with detailed reporting and export capabilities for enhanced transparency.</li>
                            <li>Discover data across multiple systems using automated search with indexing and de-normalization features.</li>
                            <li>Trace granular and aggregated data lineage with drill-back functionality and robust integration options.</li>
                            <li>Audit computations with tools for qualitative and quantitative data analysis during processes.</li>
                            <li>Analyze data usage with advanced analytics and drill-back functionality for process optimization.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/productx-feature-graphic-three.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>
  
        </div>
        
  
    </div>
  </section>
    
  
  <!--work with starts-->
  <!-- <app-trusted-by></app-trusted-by> -->
  
  
  <!--work with starts-->
  <app-contact-form></app-contact-form>