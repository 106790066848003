<!-- main container beginning here -->

<section class="section-topInner topMargin">
  <div class="container-main">
      <div class="content-flex">
          <div class="itemTitle">
              <h1>Customer Management</h1>
          </div>
          <div class="">
              <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
          </div>
      </div>
  </div>    
</section>





<section class="commonSection">
  <div class="container-main">

      <div class="section-content-flex">
          <div class="itemsOne">
              <!-- Share On component -->
              <app-share-on></app-share-on>
          </div>
          <div class="itemsTwo">
              <p>The foundation of successful customer management lies in five key pillars: acquisition and nurturing, relationship building, data-driven insights, experience management, and retention. These pillars ensure businesses attract, retain, and grow loyal customers through personalized interactions, targeted strategies, and continuous improvement.</p>
          </div>
          <div class="itemsThree">
              <div class="img-wp">
                  <img src="assets/images/customer-management-img.webp" alt="Happy Girl" width="278"
                      height="262" onerror="this.src='assets/images/customer-management-img.png'">
              </div>
          </div>
      </div>


      <div class="section-icons">

          <div class="row-flex onlyForIcons">
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/customer-acquisition-nurturing-icon.svg" alt="Customer Acquisition and Nurturing" width="90" height="90">
                      <p>Customer Acquisition and Nurturing</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/customer-relationship-building-icon.svg" alt="Customer Relationship Building" width="90" height="90">
                      <p>Customer Relationship Building</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/data-driven-insights-icon.svg" alt="Data-Driven Insights" width="90" height="90">
                      <p>Data-Driven Insights</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/customer-experience-management-icon.svg" alt="Customer Experience Management" width="90" height="90">
                      <p>Customer Experience Management</p>
                  </div>

              </div>
          </div>
      </div>           
      
      <div class="section-features-wp">
          
          <div class="row-flex">
              <div class="items-col-2">
                  <h2 class="large">Features</h2>
                  <!-- <h3 class="mb-10">Know about a few of the features</h3> -->

                  <div class="mb-20">
                      <h3 class="mb-10">Customer Acquisition and Nurturing</h3>
                      <ul class="regular">
                          <li>Attract and engage potential customers with targeted marketing campaigns.</li>
                          <li>Qualify leads and guide them through the conversion process, turning prospects into loyal clients.</li>
                      </ul>
                  </div> 
                  <div class="mb-20">
                    <h3 class="mb-10">Customer Relationship Building</h3>
                    <ul class="regular">
                        <li>Strengthen relationships with personalized communication and exceptional customer experiences.</li>
                        <li>Foster long-term loyalty through consistent and meaningful interactions.</li>
                    </ul>
                </div>
                <div class="mb-20">
                  <h3 class="mb-10">Data-Driven Insights</h3>
                  <ul class="regular">
                      <li>Leverage customer data to derive actionable insights for campaign optimization.</li>
                      <li>Personalize offers and recommendations based on customer preferences and behaviors.</li>
                  </ul>
              </div>
              <div class="mb-20">
                <h3 class="mb-10">Customer Experience Management</h3>
                <ul class="regular">
                    <li>Design customer journeys across all touchpoints to ensure smooth, positive interactions.</li>
                    <li>Exceed customer expectations by anticipating needs and delivering personalized experiences.</li>
                </ul>
            </div>
            <div class="mb-20">
              <h3 class="mb-10">Customer Retention and Loyalty</h3>
              <ul class="regular">
                  <li>Implement strategies to retain existing customers and improve lifetime value.</li>
                  <li>Drive referrals and brand advocacy through loyalty programs and excellent service.</li>
              </ul>
          </div>
              <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
              </div>
              <div class="items-col-2">
                  <div class="imgWp">
                      <img src="assets/images/customer-management-graphic.svg" alt="Features" width="643" height="673">
                  </div>                    
              </div>
          </div>

      </div>
      




  </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>