import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-seven',
  templateUrl: './merfx-sub-page-seven.component.html',
  styleUrls: ['./merfx-sub-page-seven.component.css']
})
export class MerfxSubPageSevenComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageSevenComponent) {
  context.title = 'Garage Module | Efficient Asset Management & Maintenance | signitiveAi';
  context.description = 'Enhance vehicle maintenance with Merf’s Garage Module. Digitize inspections, manage assets, and provide real-time updates to reduce downtime and improve customer satisfaction.';
  context.keywords = 'Asset Management, Garage Automation, Vehicle Maintenance, Customer Satisfaction garage module, asset management, vehicle maintenance, digital inspections, real-time tracking';
  context.seoTags();
}
