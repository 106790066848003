import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-five',
  templateUrl: './merfx-sub-page-five.component.html',
  styleUrls: ['./merfx-sub-page-five.component.css']
})
export class MerfxSubPageFiveComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageFiveComponent) {
  context.title = 'Purchase Module | Optimize Procurement & Reduce Costs | signitiveAi';
  context.description = 'Optimize procurement with Merf’s Purchase Module. Streamline asset acquisition, control spending, and reduce costs through automated workflows and vendor management.';
  context.keywords = 'Procurement, Purchase Automation, Vendor Management, Cost Reduction purchase module, procurement efficiency, vendor management, cost reduction, inventory tracking, asset acquisition';
  context.seoTags();
}