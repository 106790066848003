<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Garage</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>The Garage module optimizes maintenance and asset management, offering digital inspections, real-time tracking, and seamless integration with third-party repairs. By enhancing operational efficiency, reducing downtime, and improving service quality, it ensures that assets remain in optimal condition, boosting both customer satisfaction and sales.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/car-repair-and-maintenance.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/car-repair-and-maintenance.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/enhanced-efficiency-icon.svg" alt="Enhanced Efficiency" width="90" height="90">
                        <p>Enhanced Efficiency</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/improved-service-accuracy-icon.svg" alt="Improved Service Accuracy" width="90" height="90">
                        <p>Improved Service Accuracy</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/improved-asset-management-icon.svg" alt="Improved Asset Management" width="90" height="90">
                        <p>Improved Asset Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/real-time-visibility-icon.svg" alt="Real-Time Visibility" width="90" height="90">
                        <p>Real-Time Visibility</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li><span>Enhanced Efficiency:</span> Digitizes inspections and service processes, reducing paperwork and speeding up maintenance tasks.</li>
                            <li><span>Improved Service Accuracy:</span> Utilizes digital inspections and mobile-friendly tools for accurate data capture, ensuring high-quality service and customer satisfaction.</li>
                            <li><span>Improved Asset Management:</span> Tracks repair history and asset conditions, allowing for effective maintenance planning and reducing downtime.</li>
                            <li><span>Real-Time Visibility:</span> Provides up-to-date information on asset status and maintenance schedules, enabling proactive decision-making.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-seven.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>