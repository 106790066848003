<section class="commonSection topMargin border-b">
    <div class="container-main">

        <div class="mb-50">
            <h1 class="green">Thankyou!</h1>
            <p>We greatly appreciate you taking the time to connect with us. Your message has been received and our team will
                review it promptly.</p>
            <p>In the meantime, feel free to explore more about our services and solutions. If you have any urgent queries,
                don't hesitate to contact us directly.</p>
            <button type="button" class="btn btn-primary" aria-label="Go to Home" routerLink="/">Go to Homepage</button>
        </div>

        <div class="btn-flex">
            <p> Our Services & Solutions</p>
            <div class="">
                <button type="button" class="btn" aria-label="Car Finance" routerLink="/merfx">Car Finance</button>
                <button type="button" class="btn" aria-label="Data Lineage" routerLink="/regzen">Data Lineage</button>
                <button type="button" class="btn" aria-label="Mortgage" routerLink="/mortgage-solution0">Mortgage</button>
            </div>
        </div>


    </div>
</section>