import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-six',
  templateUrl: './merfx-sub-page-six.component.html',
  styleUrls: ['./merfx-sub-page-six.component.css']
})
export class MerfxSubPageSixComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageSixComponent) {
  context.title = 'Customer Journey Automation | Seamless Onboarding with Merf | signitiveAi';
  context.description = 'Simplify customer onboarding with Merf’s Customer Journey Automation. Leverage open banking, credit search integration, and automated decision engines to deliver a seamless experience.';
  context.keywords = 'Customer Onboarding, Open Banking Integration, Automation, Credit Search customer journey automation, onboarding efficiency, open banking integration, credit search, decision engine';
  context.seoTags();
}
