import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-one',
  templateUrl: './productx-sub-page-one.component.html',
  styleUrls: ['./productx-sub-page-one.component.css']
})
export class ProductxSubPageOneComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxSubPageOneComponent) {
  context.title = 'RegZen | AI-Driven Process Revolution | Optimize Workflows with AI | SignitiveAi';
  context.description = 'Simplify your document workflow with our advanced Document Catalogue. Effortlessly capture, validate, and enrich data, ensuring accuracy and compliance. Enhance efficiency with seamless task assignment, real-time validation, and access to extensive metadata repositories. Transform your operations with streamlined document management tools.';
  context.keywords = 'document workflow, document management, data capture, document validation, data enrichment, metadata repository, document submission';
  context.seoTags();
  }
