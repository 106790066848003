<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Customer Journey Automation</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Merf optimizes the customer onboarding process by integrating driving license data, open banking, and credit search, ensuring a seamless, fast, and secure experience. This solution reduces manual data entry, accelerates decision-making, and improves the overall customer experience, enabling dealerships to onboard customers quickly and efficiently.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/employee-working.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/employee-working.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/acquire-data-icon.svg" alt="Acquire Data" width="90" height="90">
                        <p>Acquire Data</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/integration-with-open-banking-icon.svg" alt="Integration with Open Banking" width="90" height="90">
                        <p>Integration with Open Banking</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/credit-search-integration-icon.svg" alt="Credit Search Integration" width="90" height="90">
                        <p>Credit Search Integration</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/fast-track-decisioning-icon.svg" alt="Fast-Track Decisioning" width="90" height="90">
                        <p>Fast-Track Decisioning</p>
                    </div>

                </div>
            </div>
        </div>           
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>
                    <!-- <h3 class="mb-10">Know about a few of the features</h3> -->

                    <div class="mb-20">
                        <h3 class="mb-10">Acquire Data</h3>
                        <ul class="regular">
                            <li>Simple data entry process for easy customer input.</li>
                            <li>Automatic data extraction from official documents to reduce manual work.</li>
                            <li>Instant validation ensures the accuracy of customer information.</li>
                        </ul>
                    </div> 
                    <div class="mb-20">
                        <h3 class="mb-10">Integration with Open Banking</h3>
                        <ul class="regular">
                            <li>Seamlessly connect to open banking systems for secure financial data retrieval.</li>
                            <li>Simplify the process by accessing accurate, up-to-date customer financial data.</li>
                        </ul>
                    </div>
                    <div class="mb-20">
                        <h3 class="mb-10">Credit Search Integration</h3>
                        <ul class="regular">
                            <li>Conduct instant credit checks to assess customer eligibility.</li>
                            <li>Perform comprehensive credit analysis to ensure accurate risk assessment.</li>
                        </ul>
                    </div>
                    <div class="mb-20">
                        <h3 class="mb-10">Fast-Track Decisioning</h3>
                        <ul class="regular">
                            <li>Automate the decision-making process with a configurable engine.</li>
                            <li>Apply customizable decision rules for tailored outcomes.</li>
                            <li>Provide real-time feedback to customers, ensuring quick responses.</li>
                        </ul>
                    </div>

                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-six.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>