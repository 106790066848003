import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent, HeaderComponent, UiBaseComponent } from './components';
import { RouterModule } from '@angular/router';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { TrustedByComponent } from './components/trusted-by/trusted-by.component';
import { CookiesConsentComponent } from './components/cookies-consent/cookies-consent.component';
import { WhyWorkWithUsComponent } from './components/why-work-with-us/why-work-with-us.component';
import { MoreBlogsComponent } from './components/more-blogs/more-blogs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickChatComponent } from './components/quick-chat/quick-chat.component';
import { ShareOnComponent } from './components/share-on/share-on.component';
import { FollowUsOnComponent } from './components/follow-us-on/follow-us-on.component';
import { SharedModule } from '@modules/shared';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    UiBaseComponent,
    ContactFormComponent,
    TrustedByComponent,
    CookiesConsentComponent,
    WhyWorkWithUsComponent,
    MoreBlogsComponent,
    QuickChatComponent,
    ShareOnComponent,    
    FollowUsOnComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],
  exports: [
    UiBaseComponent,
    HeaderComponent,
    ContactFormComponent,
    TrustedByComponent,
    WhyWorkWithUsComponent,
    MoreBlogsComponent,
    ShareOnComponent,
    FollowUsOnComponent
  ]
})
export class CoreModule { }
