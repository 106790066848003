<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Collection</h1>                    
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>The Collections Module empowers financial institutions with advanced tools to streamline collection processes, reduce
                    default rates, and boost profitability.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/happy-woman-shopping.webp" alt="Business Digital Transformation" width="278"
                        height="262" onerror="this.src='assets/images/happy-woman-shopping.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/increased-operational-efficiency-icon.svg" alt="Increased Operational Efficiency" width="90" height="90">
                        <p>Increased Operational Efficiency</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/increase-customer-retention-icon.svg" alt="Increase Customer Retention" width="90" height="90">
                        <p>Increase Customer Retention</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/accelerate-loan-growth-icon.svg" alt="Accelerate Loan Growth" width="90" height="90">
                        <p>Accelerate Loan Growth</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/reduce-risk-icon.svg" alt="Reduce Risk" width="90" height="90">
                        <p>Reduce Risk</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <ul class="regular mb-20">
                        <li>Automation of processes, advanced reporting, and integrated workflows improve operational efficiency.</li>
                        <li>Personalized communication, proactive support, and self-service portals enhance customer retention and satisfaction.</li>
                        <li>Efficient recovery, enhanced cash flow, and competitive strategies accelerate loan growth opportunities.</li>
                        <li>Real-time risk assessment, compliance management, and scoring help reduce operational risks.</li>
                        <li>Sub-prime, prime, and premium prime squad workflows ensure tailored solutions for diverse cases.</li>
                        <li>Integration with call centers and affordability challenge assessments simplifies and optimizes collection processes.</li>
                    </ul>

                    
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-one.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>