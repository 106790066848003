import { Component } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-productx-sub-page-three',
  templateUrl: './productx-sub-page-three.component.html',
  styleUrls: ['./productx-sub-page-three.component.css']
})
export class ProductxSubPageThreeComponent extends BaseClassUi {

  ngOnInit(): void {
    init(this);
  }

}


function init(context: ProductxSubPageThreeComponent) {
  context.title = 'RegZen | Transparent Data Evolution | Advanced Data Lineage Tracking | SignitiveAi';
  context.description = 'Transform your data management with the Lineage Hub. Track your data’s journey from origin to transformation with advanced lineage tracking, automated discovery, and seamless auditing. Gain transparency, empower compliance, and optimize processes with robust analytics and reporting tools.';
  context.keywords = 'transparent data evolution, data lineage tracking, Lineage Hub, data auditing, automated data discovery, granular lineage, data analytics, data traceability';
  context.seoTags();
  }
