<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Purchase</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Purchase Module enhances procurement efficiency by offering real-time inventory tracking, reducing stockouts, and minimizing carrying costs. It automates workflows, ensures better spend control, and streamlines the asset acquisition process, leading to more cost-effective purchasing.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/courier-man-outdoor.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/courier-man-outdoor.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/controlled-spending-icon.svg" alt="Controlled Spending" width="90" height="90">
                        <p>Controlled Spending</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/simplified-vendor-management-icon.svg" alt="Simplified Vendor Management" width="90" height="90">
                        <p>Simplified Vendor Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/automated-procurement-icon.svg" alt="Automated Procurement" width="90" height="90">
                        <p>Automated Procurement</p>
                    </div> 
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/streamlined-asset-acquisition-icon.svg" alt="Streamlined Asset Acquisition" width="90" height="90">
                        <p>Streamlined Asset Acquisition</p>
                    </div>
                </div>
            </div>

        </div>   
        

        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li><span>Controlled Spending:</span> Implements approval workflows and spend controls, preventing unauthorized purchases and overspending.</li>
                            <li><span>Simplified Vendor Management:</span> Streamlines vendor communication, ensuring faster procurement cycles and improving supplier relationships.</li>
                            <li><span>Automated Procurement:</span> Continuously monitors financial performance to drive procurement improvements and streamline workflows.</li>
                            <li><span>Streamlined Asset Acquisition:</span> Optimizes asset acquisition processes, controlling costs and improving overall procurement efficiency.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-five.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>