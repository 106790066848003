import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-two',
  templateUrl: './merfx-sub-page-two.component.html',
  styleUrls: ['./merfx-sub-page-two.component.css']
})
export class MerfxSubPageTwoComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageTwoComponent) {
  context.title = 'Reporting & Analytics | Actionable Insights for Business Growth | signitiveAi';
  context.description = 'Unlock the power of data with Merf’s Reporting & Analytics module. Gain actionable insights, monitor performance, and drive business growth with real-time reporting and customizable dashboards.';
  context.keywords = 'Reporting & Analytics, Business Insights, Real Time Data, Data Driven Decisions reporting and analytics, customizable dashboards, real-time insights, multi-dimensional analysis, operational efficiency, business growth';
  context.seoTags();
}
