import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-regzen',
  templateUrl: './regzen.component.html',
  styleUrls: ['./regzen.component.css']
})
export class RegzenComponent extends BaseClassUi implements OnInit {

  constructor() {
    super();
  }


  ngOnInit(): void {
    init(this);
  }

}

function init(context: RegzenComponent) {
  context.title = 'RegZen | GenAI Companion for Finance, Risk & Regulatory Analysts |  SignitiveAi';
  context.description = 'RegZen transforms compliance and risk management with its GenAI-powered companion for finance, risk, and regulatory professionals. By integrating cutting-edge tools like Large Language Models (LLMs), vector databases, and graph-based analytics, RegZen simplifies regulatory reporting, enhances document management, and streamlines workflows. Key features include real-time horizon scanning, full lineage tracing, and a conversational chat platform for regulatory insights. Unlock enhanced productivity, reduce compliance costs, and enable safe, sustainable growth with RegZen’s innovative solutions.';
  context.keywords = 'Regulatory Compliance AI, Finance Automation, Risk Management, Regulatory Reporting, GenAI for Analysts, Workflow Optimization, Predictive Analytics, LLM Integration, Global Regulatory Requirements, RegZen Platform';
  context.seoTags();
}
