<!-- main container beginning here -->
<section class="section-merfTop topPadding">
  <div class="innerBanner-wp">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-lg-col-8">
          <div class="banner-content">
            <h1>Transforming Car Sales & Finance Operations</h1>
            <p class="mb-30">Merf is a comprehensive solution for car sales and finance businesses, automating tasks,
              improving efficiency, and enhancing customer experiences.</p>
            <!-- <ul>
              <li>Cars</li>
              <li>Customers</li>
              <li>Contracts</li>
            </ul> -->
            <div class="btnFlex">
              <button type="button" class="btn btn-rounded-white" aria-label="Request Demo">Request Demo</button>
              <!-- <button type="button" class="btn btn-rounded-navyBlue" aria-label="View Features">View Features</button> -->
            </div>
          </div>
        </div>
        <div class="items-lg-col-3">
          <div class="img-wp">
            <img src="assets/images/merf-banner.webp" alt="An Integrated System for your 3C's" width="263" height="563"
              onerror="this.src='assets/images/merf-banner.png'">
          </div>
        </div>
      </div>
      <div class="position-carIcon">
        <img src="assets/images/car-img.webp" alt="car img" width="527" height="144"
          onerror="this.src='assets/images/car-img.png'">
      </div>
    </div>
  </div>
</section>

<section class="section-details">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1">
        <div class="card-details grayBg">
          <div class="items">
            <p>Customization and Scalability</p>
          </div>
          <div class="items">
            <p>Advanced Reporting and Analytics </p>
          </div>
          <div class="items">
            <p>Customer Portal and Self-service Options</p>
          </div>
          <!-- <div class="items">
              <p>A scalable cloud-based CRM system </p>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sectionPrimaryCard">
  <div class="card-inner">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-3 flexVcenter">
          <div class="titleFixed">
            <h2>Our Systems</h2>
          </div>
          <div class="card-primary cardBlue card-next-level--">
            <h3>Sales</h3>
            <p> Speed up contract creation and approval with our Underwriting Process solution. Ensure compliance,
              optimize sales processes, and simplify deposit verification and contract approval. Manage customer lists
              efficiently and expedite sales workflows.</p>
            <div class="readMore">
              <a href="javascript:void(0)" class="whiteIcon" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_FOUR)">Go to Page</a>
            </div>
          </div>
        </div>
        <div class="items-col-8">
          <div class="leftSpace">
            <div class="row-flex">
              <div class="items-col-2">
                <div class="card-primary blueLight mb-20 cardMarginTop">
                  <h3>Collection</h3>
                  <p>The Collections Module streamlines collections, reducing defaults and boosting profitability for
                    financial institutions.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_ONE)">Go to
                      Page</a>
                  </div>
                </div>
                <div class="card-primary">
                  <h3>Reporting & Analytics</h3>
                  <p>Use reporting and dashboards to make informed decisions, optimize operations, and drive growth.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_TWO)">Go to
                      Page</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2 cardFlex">
                <div class="card-primary orderOne mb-20">
                  <h3>Underwriting & Automation</h3>
                  <p>Accelerate car sales and lending with our streamlined underwriting solution.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_THREE)">Go to
                      Page</a>
                  </div>
                </div>
                <div class="card-primary blueLight orderTwo cardMarginBtm">
                  <h3>Garage</h3>
                  <p>Simplify maintenance with digital inspections, real-time tracking, and third-party repair
                    integration using the Garage Module.</p>
                  <div class="readMore">
                    <!-- <a href="javascript:void(0)" role="button" >Go to Page</a> -->
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_SEVEN)">Go to Page</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="sectionDashboard merfxGridBg">
  <div class="container-main">
    <div class="row-flex imgOrder">
      <div class="items-lg-col-5 textVCenter">
        <div class="">
          <h2>Merf-Grid</h2>
          <div class="imgForMobile">
            <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
          </div>
          <p class="mb-15">Our ecosystem, Merf-grid, provides a variety of valuable benefits tailored for business
            owners, including:</p>
          <ul class="regular mb-30 text-white">
            <li><span>Instant Publishing:</span> Dealers can instantly publish listings on partnered sites powered by
              MERF.</li>
            <li><span>Marketing Engines Integration:</span> Easily list vehicles on platforms like Autotrader, eBay,
              Facebook, and more.</li>
            <li><span>Dedicated Portals:</span> Customized portals for customers and partners to efficiently manage
              business operations and data.</li>
            <li><span>AI/ML Solutions:</span> AI-powered chatbots built with advanced ML technologies enhance
              interactions for businesses and customers.</li>
          </ul>
          <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
        </div>
      </div>
      <!-- <div class="items-lg-col-7">
        <div class="imgWrapper largeImg">
          <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
        </div>
      </div> -->
    </div>
  </div>


  <!-- <div class="container-main">
    <div class="row-flex">
      <div class="items-col-5">
        <div class="content-wp">
          <h2>Dynamic Dashboard</h2>
          <p class="mb-25">Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I'm a great
            place for you to tell a story and let your users know a little more about you. </p>
          <button type="button" class="btn btn-primary btn-rounded-blue2" aria-label="Request Demo">Request Demo</button>
        </div>
      </div>
      <div class="items-col-7">
        <div class="graph-wp">
          <img src="assets/images/dynamic-dashboard-graph.svg" alt="Dynamic Dashboard" width="690" height="338">
        </div>
      </div>
    </div>
  </div> -->
</section>



<!-- <div class="container-main">
  <div class="row-flex imgOrder">
    <div class="items-lg-col-2 textVCenter">
      <div class="">
        <h2>See all Applications in One Place</h2>
        <div class="imgForMobile">
          <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
        </div>
        <p class="mb-30">
          Lorem ipsum dolor. I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a
          story and let your users know a little more about you.
        </p>
        <button type="button" class="btn btn-rounded-navyBlue btn-outLine-navyBlue" aria-label="Request Demo">Request
          Demo</button>
      </div>
    </div>
    <div class="items-lg-col-2">
      <div class="imgWrapper">
        <img src="assets/images/all-applications-img.svg" alt="See all Applications in One Place" width="430" height="435">
      </div>
    </div>
  </div>
</div> -->



<section class="sectionSecondaryCard">
  <div class="cardInner">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-col-8 customCol70 relative">
          <div class="itemInner">
            <div class="titleFixed">
              <h2>Features</h2>
            </div>
            <div class="row-flex">
              <div class="items-col-2">
                <div class="card-primary blueLight mb-20">
                  <h3>IT Service Management</h3>
                  <p>Merf ensures reliability, security, and continuous improvement for a high-quality user experience.
                  </p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_EIGHT)">Go to Page</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary">
                  <h3>Incident Management</h3>
                  <p>Incident Management streamlines issue resolution with self-service, courtesy cars, and quick
                    repairs to enhance satisfaction.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_NINE)">Go to Page</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary mb-20 forCardBg1">
                  <h3>Customer Journey Automation</h3>
                  <p>Merf uses driving license, open banking, and credit data for seamless, efficient customer
                    onboarding and decision-making.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_SIX)">Go to Page</a>
                  </div>
                </div>
              </div>
              <div class="items-col-2">
                <div class="card-primary blueLight forCardBg2">
                  <h3>Customer Management</h3>
                  <p>Attract, qualify, and convert leads into loyal customers with targeted marketing and lead
                    management strategies.</p>
                  <div class="readMore">
                    <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_ELEVEN)">Go to Page</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="items-col-3 customCol30">
          <div class="card-primary cardBlue mb-20">
            <h3>Enterprise Integration</h3>
            <p>Seamlessly integrate third-party apps with customizable triggers, real-time data sync, and secure,
              no-code automation.</p>
            <div class="readMore">
              <a href="javascript:void(0)" class="whiteIcon" role="button" (click)="onReadMoreClick(paths.PATH_MERFX_TEN)">Go to Page</a>
            </div>
          </div>
          <div class="card-primary cardBlue">
            <h3>Purchase</h3>
            <p>Optimize procurement with real-time inventory tracking to reduce stockouts and carrying costs via the
              Purchase Module.</p>
            <div class="readMore">
              <a href="javascript:void(0)" class="whiteIcon" role="button"
                (click)="onReadMoreClick(paths.PATH_MERFX_FIVE)">Go to Page</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


<section class="sectionDocumentManagement underwritingBg">
  <div class="container-main">
    <div class="row-flex imgOrder">
      <div class="items-lg-col-2">
        <h2>Underwriting & Automation</h2>
        <!-- <div class="imgForMobile">
          <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
        </div> -->
        <ul class="regular mb-30 text-white">
          <li><span>Case Management:</span> Each loan application follows a structured workflow, guiding underwriters through the evaluation process.</li>
          <li><span>Document Verification:</span> Automated verification ensures compliance and speeds up processing time.</li>
          <li><span>Intelligent Decisioning:</span> Advanced algorithms assess creditworthiness and risk for faster, accurate loan decisions.</li>
          <li><span>Seamless Communication:</span> Integrated tools enable efficient interaction among underwriters, customers, and stakeholders.</li>
        </ul>
        <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
      </div>
      <!-- <div class="items-lg-col-2">
        <div class="imgWrapper">
          <img src="assets/images/document-management-img2.svg" alt="Document Management" width="625" height="373">
        </div>
      </div> -->
    </div>
  </div>

</section>


<!--work with starts-->
<app-contact-form></app-contact-form>