import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-eleven',
  templateUrl: './merfx-sub-page-eleven.component.html',
  styleUrls: ['./merfx-sub-page-eleven.component.css']
})
export class MerfxSubPageElevenComponent extends BaseClassUi implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageElevenComponent) {
  context.title = 'Customer Management | Build Loyalty with Data-Driven Insights | signitiveAi';
  context.description = 'Build stronger relationships with Merf’s Customer Management tools. Use data-driven insights to improve customer retention, enhance experiences, and drive long-term loyalty.';
  context.keywords = 'Customer Retention, Data Driven Insights, Relationship Management, Experience Enhancement, customer management, relationship building, data-driven insights, customer retention, loyalty strategies';
  context.seoTags();
}
