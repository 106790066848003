<!-- main container beginning here -->

<section class="section-topInner topMargin">
  <div class="container-main">
      <div class="content-flex">
          <div class="itemTitle">
              <h1>Enterprise Integration</h1>
          </div>
          <div class="">
              <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
          </div>
      </div>
  </div>    
</section>


<section class="commonSection">
  <div class="container-main">

      <div class="section-content-flex">
          <div class="itemsOne">
              <!-- Share On component -->
              <app-share-on></app-share-on>
          </div>
          <div class="itemsTwo">
              <p>Integration allows users to seamlessly connect with various third-party apps and services, automating workflows without coding. It offers customizable triggers and actions, real-time data sync, and a user-friendly interface, making it easy to integrate and scale across different tools while ensuring secure data handling.</p>
          </div>
          <div class="itemsThree">
              <div class="img-wp">
                  <img src="assets/images/enterprise-integration-img.webp" alt="Happy People" width="278"
                      height="262" onerror="this.src='assets/images/enterprise-integration-img.png'">
              </div>
          </div>
      </div>


      <div class="section-icons">

          <div class="row-flex onlyForIcons">
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/no-code-integrations-icon.svg" alt="No-Code Integrations" width="90" height="90">
                      <p>No-Code Integrations</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/wide-compatibility-icon.svg" alt="Wide Compatibility" width="90" height="90">
                      <p>Wide Compatibility</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/automated-workflows-icon.svg" alt="Automated Workflows" width="90" height="90">
                      <p>Automated Workflows</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/customizable-triggers-actions-icon.svg" alt="Customizable Triggers & Actions" width="90" height="90">
                      <p>Customizable Triggers & Actions</p>
                  </div>

              </div>
          </div>
      </div>           
      
      <div class="section-features-wp">
          
          <div class="row-flex">
              <div class="items-col-2">
                  <h2 class="large">Features</h2>
                  <!-- <h3 class="mb-10">Know about a few of the features</h3> -->

                  <div class="mb-20">
                      <ul class="regular">
                          <li>Easily connect Merf with various third-party apps and services without the need for coding.</li>
                          <li>Supports a broad range of applications, enabling seamless integration across different tools and platforms.</li>
                          <li>Set up automated workflows that trigger actions across multiple systems, improving efficiency and reducing manual tasks.</li>
                          <li>Create custom triggers and actions to tailor integrations to specific business needs.</li>
                      </ul>
                  </div> 
                 
                  <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
              </div>
              <div class="items-col-2">
                  <div class="imgWp">
                      <img src="assets/images/enterprise-integration-graphic.svg" alt="Features" width="643" height="673">
                  </div>                    
              </div>
          </div>

      </div>
      




  </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>