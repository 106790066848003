<!-- main container beginning here -->

<section class="section-topInner topMargin">
  <div class="container-main">
      <div class="content-flex">
          <div class="itemTitle">
              <h1>IT Service Management</h1>
          </div>
          <div class="">
              <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
          </div>
      </div>
  </div>    
</section>


<section class="commonSection">
  <div class="container-main">

      <div class="section-content-flex">
          <div class="itemsOne">
              <!-- Share On component -->
              <app-share-on></app-share-on>
          </div>
          <div class="itemsTwo">
              <p>We ensures that the product is reliable, secure, and continuously improving, providing a high-quality experience for users.</p>
          </div>
          <div class="itemsThree">
              <div class="img-wp">
                  <img src="assets/images/it-service-management-img.webp" alt="Working woman" width="278"
                      height="262" onerror="this.src='assets/images/it-service-management-img.png'">
              </div>
          </div>
      </div>


      <div class="section-icons">

          <div class="row-flex onlyForIcons">
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/incident-management-icon.svg" alt="Incident Management" width="90" height="90">
                      <p>Incident Management</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/data security-compliance-icon.svg" alt="Data Security & Compliance" width="90" height="90">
                      <p>Data Security & Compliance</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/continuous-improvement-innovation-icon.svg" alt="Continuous Improvement & Innovation" width="90" height="90">
                      <p>Continuous Improvement & Innovation</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/service-desk-support-icon.svg" alt="Service Desk & Support" width="90" height="90">
                      <p>Service Desk & Support</p>
                  </div>

              </div>
          </div>
      </div>           
      
      <div class="section-features-wp">
          
          <div class="row-flex">
              <div class="items-col-2">
                  <h2 class="large">Features</h2>
                  <!-- <h3 class="mb-10">Know about a few of the features</h3> -->

                  <div class="mb-20">
                      <ul class="regular">
                          <li><span>Incident Management:</span> Proactive monitoring and response to incidents, along with detailed root cause analysis, ensures minimal disruption to services.</li>
                          <li><span>Data Security & Compliance:</span> Secure access control and data encryption protocols maintain the integrity and confidentiality of sensitive information.</li>
                          <li><span>Continuous Improvement & Innovation:</span> Ongoing feedback loops, training programs, and agile development practices drive the continuous enhancement of services and products.</li>
                          <li><span>Service Desk & Support:</span> 24/7 helpdesk support, tiered service levels, and a comprehensive knowledge base empower users to resolve issues efficiently.</li>
                          <li><span>Change Management:</span> Controlled deployments, rigorous testing, and clear user communication minimize the risks associated with system updates.</li>
                          <li><span>Performance Management:</span> Monitoring and optimization of services, coupled with capacity planning and SLA management, ensures high service availability and performance.</li>
                      </ul>
                  </div> 
                 
                  <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
              </div>
              <div class="items-col-2">
                  <div class="imgWp">
                      <img src="assets/images/it-service-management-graphic.svg" alt="Features" width="643" height="673">
                  </div>                    
              </div>
          </div>

      </div>
      




  </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>