import { Component, OnInit } from '@angular/core';
import { BaseClassUi } from '@modules/core/base';

@Component({
  selector: 'app-merfx-sub-page-ten',
  templateUrl: './merfx-sub-page-ten.component.html',
  styleUrls: ['./merfx-sub-page-ten.component.css']
})
export class MerfxSubPageTenComponent extends BaseClassUi implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
    init(this);
  }

}

function init(context: MerfxSubPageTenComponent) {
  context.title = 'Enterprise Integration | Seamless No-Code Workflow Automation | signitiveAi';
  context.description = 'Connect seamlessly with third-party apps using Merf’s Enterprise Integration tools. Automate workflows, sync data in real time, and scale your integrations securely without coding.';
  context.keywords = 'Integration, No Code Tools, Automated Workflows, Data Sync enterprise integration, no-code tools, automated workflows, real-time data sync, secure integrations';
  context.seoTags();
}


