<!-- main container beginning here -->
<section class="section-santriDoc-banner topPadding">
  <div class="innerSpace regzenBg">
    <div class="container-main">
      <div class="row-flex">
        <div class="items-lg-col-7 itemTitle">
          <h1>GenAi companion for Finance, Risk & Regulatory Analysts</h1>
          <p>Helping financial institutions to be compliant all the time.</p>
          <div class="btn-flex">
            <button type="button" class="btn btn-rounded-white" aria-label="Request Demo">Request Demo</button>
            <button type="button" class="btn btn-rounded-navyBlue" aria-label="Get Started">Get Started</button>
          </div>
        </div>
        <!-- <div class="items-lg-col-2 itemImg">
          <div class="img-wp">
            <img src="images/mortgage-solution-login-img.svg" alt="We automate your business processes" width="270" height="560">
          </div>                     
        </div> -->
      </div>      
    </div>
  </div>         
</section>

<section class="section-details">
  <div class="container-main">
    <div class="row-flex">
      <div class="items-col-1">
        <div class="cardRegZen">
          <div class="">
            <p>Efficient Document Management</p>
          </div>
          <div class="">
            <p>Data Transparency</p>
          </div>
          <div class="">
            <p>AI-Powered Process Improvement</p>
          </div>
          <div class="">
            <p>Workflow Optimization</p>
          </div>
        </div>
      </div>
    </div>      
  </div>         
</section>

<section class="sectionPrimaryCardRegzen">
  <div class="card-inner">
    <div class="container-main">
      <div class="row-flex regZenCards">
        <div class="items-col-1">
          <h2>Our Systems</h2>
        </div>
        <div class="items-col-3">
          <div class="card-primary">
            <img src="assets/images/icons/systems-icon-1.svg" alt="System icon" width="90" height="90" />
            <h3>OneRegWorld</h3>
            <p>A global repository of regulatory requirements enables real-time horizon scanning to track changes, uses predictive analytics to detect reporting issues early, and offers compliance assessments aligned with business KPIs.</p>
            <!-- <div class="readMore">
              <a href="javascript:void(0)" role="button"  (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_THREE)">Read More</a>
            </div> -->
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-primary blueLight">
            <img src="assets/images/icons/systems-icon-2.svg" alt="System icon" width="90" height="90" />
            <h3>Full Lineage & Tracing </h3>
            <p>RegCognet acts as an experts for each and every report being prepared for regulators with an ability to explain the full lineage and respective regulatory context for each cell on a report. It also offers a natural language interface to the regulatory process.</p>
            <!-- <div class="readMore">
              <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_TWO)">Read More</a>
            </div> -->
          </div>
        </div>
        <div class="items-col-3">
          <div class="card-primary">
            <img src="assets/images/icons/systems-icon-3.svg" alt="System icon" width="90" height="90" />
            <h3>Chat platform</h3>
            <p>“ask me anything” chat platform for reg & risk universe. Users can ask questions with regards to regulatory reporting needs across the world.</p>
            <!-- <div class="readMore">
              <a href="javascript:void(0)" role="button"  (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_THREE)">Read More</a>
            </div> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="sectionCombineDTwo">
  <div class="container-main">
    <div class="row-flex imgOrder">
      <div class="items-lg-col-7 textVCenter">
        <div class="">
          <h2>Combine</h2>
          <div class="imgForMobile">
            <img src="assets/images/combine-diagram.svg" alt="Combine diagram" width="480" height="380">
          </div>
          <p class="lg-text">Integrate LLM, knowledge domains, vector, and graph databases into a chat platform for enhanced user experience.</p>
          <ul class="regular">
            <li>We are uniquely positioned to provide high quality interactions because of the partner ecosystem and access to large amount of risk and regulatory reporting information, and metadata collected over the years. For instance, regulatory reporting has been modelled as a graph database to allow the GenAI to easily query and summarize complex questions about cell in reports.</li>
          </ul>
          <div class="bulletPoints-wp">
            <div class="">
              <p class="circle">Large Language Model (OpenAI GPT4) and Retrieval Augmented Generation (RAG) </p>
              <p>Vector and Graph Databases (Qdrant, neo4j)</p>
              <p>Knowledge Domains (Llama Index)</p>
              <p class="circle">“Ask me anything” chat platform (Chainlit)</p>
            </div>
            <p class="circle">Python libraries (Sklearn)</p>
          </div>
          <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
        </div>
      </div>
      <div class="items-lg-col-5">
        <div class="imgWrapper">
          <img src="assets/images/combine-diagram.svg" alt="Combine diagram" width="480" height="380">
        </div>
      </div>
    </div>
  </div>  

</section>

<section class="sectionFeatures">
  <div class="container-main">
    <div class="row-flex"> 
      <div class="items-col-1"><h2>Features</h2></div>
      <div class="items-col-1 mb-0">
          <div class="row-flex">
            <div class="items-col-2">
              <div class="card-primary blueLight">
                <h3>Simplify Document Workflow</h3>
                <p>Streamline document management, update, and validate with ease, ensuring accuracy and compliance effortlessly.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_ONE)">Go to Page</a>
                </div>
              </div>
            </div>
            <div class="items-col-2">
              <div class="card-primary">
                <h3>Optimize Workflows</h3>
                <p>Define, adapt, and manage workflows seamlessly, minimizing errors and enhancing operational efficiency.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_TWO)">Go to Page</a>
                </div>
              </div>
            </div>
            <div class="items-col-2">
              <div class="card-primary forCardBg1">
                <h3>Elevate Processes with AI</h3>
                <p>Speed up contract creation, ensure compliance, and optimize sales workflows with AI-powered tools.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_FOUR)">Go to Page</a>
                </div>
              </div>
            </div>
            <div class="items-col-2">
              <div class="card-primary blueLight forCardBg2">
                <h3>Data Journey Insights</h3>
                <p>Track data origins and transformations, ensuring transparency and informed decision-making with our Lineage Hub.</p>
                <div class="readMore">
                  <a href="javascript:void(0)" role="button" (click)="onReadMoreClick(paths.PATH_PRODUCTX_SUB_PAGE_THREE)">Go to Page</a>
                </div>
              </div>
            </div>
          </div>
      </div>

      <!-- <div class="items-col-3">
        <div class="card-primary cardBlue">
          <h3>Effortless Workflow Customization</h3>
          <p>Configure workflows, monitor performance, and make real-time adjustments through an intuitive, user-friendly interface.</p>
            <div class="readMore">
              <a href="javascript:void(0)" class="whiteIcon" role="button">Go to Page</a>
            </div>
        </div>
      </div> -->
      
    </div>
  </div>
</section>

<section class="sectionLineageHub lineageHubBg">
<div class="container-main">
  <div class="row-flex imgOrder">
    <div class="items-lg-col-5">
      <h2>Lineage Hub: Gain Insights into Your Data Journey</h2>
      <ul class="regular mb-30 text-white">
        <li>Tracking the origin and journey of data to its current state, with detailed reporting and export functionalities.</li>
        <li>Automated search features for discovering data across multiple systems, with indexing and de-normalization capabilities.</li>
        <li>Tracking Granular And Aggregated Data Lineage, With Drill-Back Functionality And Integration Options.</li>
        <li>And so much more...</li>
      </ul>
      <button type="button" class="btn btn-rounded-white2" aria-label="Request Demo">Request Demo</button>
    </div>
  </div>
</div>

</section>

<section class="sectionGlobal">
<div class="container-main">
  <div class="row-flex">
    <div class="items-col-1">

      <div class="globalFlex">
        <div>
          <img src="assets/images/icons/global-icon-1.svg" alt="Icon" width="90" height="90" />
          <p class="bold mb-5">Interactive Visualization</p>
          <p>Share real-time data views across your organization with ease.</p>
        </div>
        <div>
          <img src="assets/images/icons/global-icon-2.svg" alt="Icon" width="90" height="90" />
          <p class="bold mb-5">Comprehensive Reporting</p>
          <p>Gain insights into process performance with visual representations.</p>
        </div>
        <div>
          <img src="assets/images/icons/global-icon-3.svg" alt="Icon" width="90" height="90" />
          <p class="bold mb-5">Global Document Ontology</p>
          <p>Access a vast range of document vocabulary for improved document management.</p>
        </div>
        <div>
          <img src="assets/images/icons/global-icon-4.svg" alt="Icon" width="90" height="90" />
          <p class="bold mb-5">Customizable Workflows</p>
          <p>Define business steps, dependencies, and adapt workflows as needed.</p>
        </div>
      </div>        
      
    </div>
  </div>
</div>
</section>

<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>
