<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Sales</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>The Sales Module streamlines the sales process by enabling the sales team to manage leads from the website, contact customers, and assess their car affordability based on income, ensuring an efficient and transparent sales journey.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/apprentice-engineer.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/apprentice-engineer.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/sales-icon-1.svg" alt="Lead Management" width="90" height="90">
                        <p>Lead Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/sales-icon-2.svg" alt="Customer Interaction" width="90" height="90">
                        <p>Customer Interaction</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/sales-icon-3.svg" alt="Affordability Assessment" width="90" height="90">
                        <p>Affordability Assessment</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/sales-icon-4.svg" alt="Vehicle Selection" width="90" height="90">
                        <p>Vehicle Selection</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li>Capture and manage leads from the website, ensuring no potential customer is missed.</li>
                            <li>Facilitate direct communication with customers to assess their interest in specific vehicles and gather essential details.</li>
                            <li>Evaluate the customer's affordability based on income, tailoring car options to fit their budget.</li>
                            <li>Provide easy-to-use screens for vehicle selection, guiding customers through available options.</li>
                            <li>Integrate credit limit checks to determine borrowing capacity before proceeding with the sale.</li>
                            <li>Use a dynamic payment calculator to work out monthly payments based on terms, interest rates, and loan conditions.</li>
                            <li>Ensure seamless transition from sales to underwriting for quick approvals, minimizing delays.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-four.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>