<!-- main container beginning here -->

<section class="section-topInner topMargin">
  <div class="container-main">
      <div class="content-flex">
          <div class="itemTitle">
              <h1>Incident Management</h1>
          </div>
          <div class="">
              <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
          </div>
      </div>
  </div>    
</section>


<section class="commonSection">
  <div class="container-main">

      <div class="section-content-flex">
          <div class="itemsOne">
              <!-- Share On component -->
              <app-share-on></app-share-on>
          </div>
          <div class="itemsTwo">
              <p>The Incident Management System streamlines aftersales issue resolution by allowing customers to raise incidents via a self-service portal, ensuring quick service and providing courtesy cars for repairs. The system focuses on getting customers back on the road promptly, ultimately enhancing satisfaction and trust.</p>
          </div>
          <div class="itemsThree">
              <div class="img-wp">
                  <img src="assets/images/incident-management-img.webp" alt="Happy Woman" width="278"
                      height="262" onerror="this.src='assets/images/incident-management-img.png'">
              </div>
          </div>
      </div>


      <div class="section-icons">

          <div class="row-flex onlyForIcons">
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/efficient-issue-resolution-icon.svg" alt="Efficient Issue Resolution" width="90" height="90">
                      <p>Efficient Issue Resolution</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/timely-service-icon.svg" alt="Timely Service" width="90" height="90">
                      <p>Timely Service</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/customer-empowerment-icon.svg" alt="Customer Empowerment" width="90" height="90">
                      <p>Customer Empowerment</p>
                  </div>
              </div>
              <div class="items-col-2">
                  <div class="innerIcon">
                      <img src="assets/images/icons/enhanced-customer-trust-icon.svg" alt="Enhanced Customer Trust" width="90" height="90">
                      <p>Enhanced Customer Trust</p>
                  </div>

              </div>
          </div>
      </div>           
      
      <div class="section-features-wp">
          
          <div class="row-flex">
              <div class="items-col-2">
                  <h2 class="large">Features</h2>
                  <!-- <h3 class="mb-10">Know about a few of the features</h3> -->

                  <div class="mb-20">
                      <ul class="regular">
                          <li>Streamline the handling of aftersales issues, ensuring that customer incidents are resolved quickly and effectively.</li>
                          <li>Prioritize timely resolutions, minimizing downtime and getting customers back on the road as quickly as possible.</li>
                          <li>Empower customers through the self-service portal, allowing them to easily raise incidents and improving their satisfaction.</li>
                          <li>Build stronger customer relationships and loyalty by providing prompt and reliable aftersales support.</li>
                      </ul>
                  </div> 
                 
                  <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
              </div>
              <div class="items-col-2">
                  <div class="imgWp">
                      <img src="assets/images/incident-management-graphic.svg" alt="Features" width="643" height="673">
                  </div>                    
              </div>
          </div>

      </div>
      




  </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>