<!-- main container beginning here -->

<section class="section-topInner topMargin">
    <div class="container-main">
        <div class="content-flex">
            <div class="itemTitle">
                <h1>Underwriting & Automation</h1>
            </div>
            <div class="">
                <button type="button" class="btn btn-rounded-white"  aria-label="Request Demo">Request Demo</button>                    
            </div>
        </div>
    </div>    
</section>





<section class="commonSection">
    <div class="container-main">

        <div class="section-content-flex">
            <div class="itemsOne">
                <!-- Share On component -->
                <app-share-on></app-share-on>
            </div>
            <div class="itemsTwo">
                <p>Streamline Your Car Sales and Lending Process with Our Underwriting and Automation Solution, helping you assess, approve, and finalize loans faster and more efficiently.</p>
            </div>
            <div class="itemsThree">
                <div class="img-wp">
                    <img src="assets/images/happy-people-shaking-hands.webp" alt="Happy People" width="278"
                        height="262" onerror="this.src='assets/images/happy-people-shaking-hands.png'">
                </div>
            </div>
        </div>


        <div class="section-icons">

            <div class="row-flex onlyForIcons">
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/underwriting-automation-icon-1.svg" alt="Case Management" width="90" height="90">
                        <p>Case Management</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/underwriting-automation-icon-2.svg" alt="Comprehensive Document Verification" width="90" height="90">
                        <p>Comprehensive Document Verification</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/underwriting-automation-icon-3.svg" alt="Intelligent Decisioning" width="90" height="90">
                        <p>Intelligent Decisioning</p>
                    </div>
                </div>
                <div class="items-col-2">
                    <div class="innerIcon">
                        <img src="assets/images/icons/underwriting-automation-icon-4.svg" alt="Contract Finalization" width="90" height="90">
                        <p>Contract Finalization</p>
                    </div>
                </div>
            </div>

        </div>   

        
        
        <div class="section-features-wp">
            
            <div class="row-flex">
                <div class="items-col-2">
                    <h2 class="large">Features</h2>

                    <div class="mb-30">
                        <h3 class="mb-10">Know about a few of the features</h3>
                        <ul class="regular">
                            <li><span>Case Management:</span> Each loan application is treated as a unique case, with a structured workflow to guide underwriters through the evaluation process.</li>
                            <li><span>Comprehensive Document Verification:</span> Automated document verification ensures compliance and reduces processing time.</li>
                            <li><span>Intelligent Decisioning:</span> Our rule-based engine leverages advanced algorithms to assess creditworthiness, affordability, and risk, enabling faster and more accurate decisions.</li>
                            <li><span>Contract Finalization:</span> Streamlined contract generation and e-signature process accelerates the loan approval and disbursement.</li>
                            <li><span>Seamless Communication:</span> Integrated communication tools facilitate efficient interaction between underwriters, customers, and other stakeholders.</li>
                        </ul>
                    </div> 
                    <button type="button" aria-label="Request Demo" class="btn btn-rounded-navyBlue btn-outLine-navyBlue">Request Demo</button>
                </div>
                <div class="items-col-2">
                    <div class="imgWp">
                        <img src="assets/images/features-graphic-three.svg" alt="Features" width="643" height="673">
                    </div>                    
                </div>
            </div>

        </div>
        




    </div>
</section>




<!--work with starts-->
<!-- <app-trusted-by></app-trusted-by> -->


<!--work with starts-->
<app-contact-form></app-contact-form>